import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.devis.type === 'import')?_c(VBtn,_vm._g(_vm._b({staticClass:"mt-4",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-currency-eur")]),_vm._v(" Modifier le montant total ")],1):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VToolbar,{attrs:{"color":"primary"}},[_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v("Modifier le montant total")])],1),_c(VCardText,{staticClass:"my-2"},[_c(VTextField,{attrs:{"type":"number","step":"0.01","label":"Montant total","hint":"Veuillez insérer le montant du devis importé.","persistent-hint":""},model:{value:(_vm.montantTotal),callback:function ($$v) {_vm.montantTotal=$$v},expression:"montantTotal"}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"disabled":_vm.editing,"color":"primary"},on:{"click":_vm.changeMontant}},[_vm._v("Modifier")]),(_vm.editing)?_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }