import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{staticClass:"mt-1",attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"white--text darken-2 mt-4",attrs:{"color":"blue"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-currency-eur")]),_vm._v(" Signer le devis")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary"}},[_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v("Signer le devis")])],1),_c(VCardText,{staticClass:"pa-4"},[_c('h1',{staticClass:"text-center"},[_vm._v("Signer le devis")]),_c('div',{staticClass:"mt-4"},[_c('h3',[_vm._v("Signature du maître d'ouvrage")]),_c('div',{staticClass:"d-flex align-center justify-center"},[_c('signature-area',{model:{value:(_vm.signature.maitre_ouvrage),callback:function ($$v) {_vm.$set(_vm.signature, "maitre_ouvrage", $$v)},expression:"signature.maitre_ouvrage"}})],1)]),_c('div',{staticClass:"mt-4"},[_c('h3',[_vm._v("Signature de l'entreprise")]),_c('div',{staticClass:"d-flex align-center justify-center"},[_c('signature-area',{model:{value:(_vm.signature.entreprise),callback:function ($$v) {_vm.$set(_vm.signature, "entreprise", $$v)},expression:"signature.entreprise"}})],1)]),_c('div',{staticClass:"mt-4"},[_c('h3',[_vm._v("Signature de la DEAL")]),_c('div',{staticClass:"d-flex align-center justify-center"},[_c('signature-area',{model:{value:(_vm.signature.deal),callback:function ($$v) {_vm.$set(_vm.signature, "deal", $$v)},expression:"signature.deal"}})],1)])]),_c(VCardActions,[_c(VBtn,{attrs:{"disabled":_vm.loading,"color":"primary"},on:{"click":_vm.signDevis}},[_vm._v("Signer")]),(_vm.loading)?_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }